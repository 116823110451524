<form [formGroup]="formGroup" *ngIf="fetchingData && !loadingAccount else loadingTemplate">
  <fieldset [disabled]="disabled">
    <div class="row separator mb-5">
      <div class="col card px-0 m-0">
        <div class="row">
          <div class="px-5 col-12 titlebar">
            <h3>Dados do usuário</h3>
          </div>

          <div class="col-12 col-lg-3 ml-3">
            <app-image-input id="profile_image" [value]="image" [disabled]="formGroup.disabled"
              (onImageChange)="onImageSelect($event)">
            </app-image-input>
          </div>

          <div class="col">
            <div class="row remove-padding">
              <div class="col-sm-12 p-0 pb-3">
                <phone-number-input label="Telefone" [formGroup]="formGroup" (change)="searchAccount()">
                </phone-number-input>
              </div>

              <div class="col-sm-4 p-0 pb-3">
                <app-basic-input id="document_number" controlName="document_number" [formGroup]="formGroup" label="CPF"
                  [maskOptions]="{mask: '000.000.000-00'}"></app-basic-input>
              </div>

              <div class="col-sm-8 pb-3">
                <app-basic-input id="name" controlName="name" [formGroup]="formGroup" label="Nome completo">
                </app-basic-input>
              </div>

              <div class="col-sm-4 p-0">
                <app-basic-input type="date" id="birthdate" controlName="birthdate" label="Data de nascimento"
                  [formGroup]="formGroup">
                </app-basic-input>
              </div>

              <div class="col-sm-8 m-0">
                <app-basic-input id="email" controlName="email" [formGroup]="formGroup" label="E-mail">
                </app-basic-input>
              </div>

              <div class="col-5 p-0 pt-4 checkbox-option" *ngIf="isSuperUserOrStaff">
                <input id="can_approve_exam" type="checkbox" formControlName="can_approve_exam">
                <label for="can_approve_exam">Aprovar Exame</label>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row separator">
      <div class="col card">
        <div class="row">
          <div class="px-2 col-12">
            <h3>Clinicas</h3>
          </div>
          <div class="px-2 col-12" *ngIf="isSuperUserOrStaff">
            <app-select-input id="clinics" [formGroup]="formGroup" [items]="clinicList" [hideSelected]="true"
              [addTag]="false" [multiple]="true" [clearable]="false" [searchable]="false">
            </app-select-input>
          </div>
          <div class="px-2 col-12" *ngIf="!isSuperUserOrStaff">
            <app-badge-input id="clinics" [formGroup]="formGroup" [items]="clinicList" [clearButton]="canSave" (onClick)="revokeClinic($event)">
            </app-badge-input>
          </div>
        </div>
      </div>
    </div>


    <div class="row separator">
      <div class="col card">
        <div class="row">
          <div class="px-2 col-12 titlebar">
            <h3>Permissões</h3>

            <div class="select-all checkbox-option" (click)="toggleAllPermissions($event)">
              <input type="checkbox" id="selectAllPermissions" [disabled]="!is_active || !canSave">
              <label for="selectAllPermissions" [ngStyle]="(!is_active || !canSave) ? {'cursor': 'default'} : {'cursor': 'pointer'}"> Selecionar tudo </label>
            </div>
          </div>

          <ng-container *ngIf="userPermissions">
            <div class="col-6 col-md-4" *ngFor="let model of permissionModels">
              <div class="checkbox-field mt-4 mb-4">
                <label class="checkbox-field__title">{{ model }}</label>

                <div class="row" *ngFor="let permission of getPermissions(model)">
                  <div class="col checkbox-option p-0">
                    <input type="checkbox" [value]="permission['id']" [id]="permission['codename']"
                      [checked]="isChecked(permission)" [disabled]="isDisabled(permission)"
                      (change)="onCheckChange($event)">
                    <label [for]="permission['codename']" [ngStyle]="isDisabled(permission) ? {'cursor': 'default'} : {'cursor': 'pointer'}">{{ permission['name'] }}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row justify-content-end mt-5 mb-5">
      <div class="col-12 col-md-3 mb-3">
        <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
          Cancelar
        </button>
      </div>

      <div class="col-12 col-md-3 mb-3" *ngIf="passwordChange">
        <button class="btn btn-primary btn-lg"
        type="button"
        (click)="resetPassword()">
          Redefinir senha
          <span class="spinner-borde"></span>
        </button>
      </div>

      <div class="col-12 col-md-3 mb-3" *ngIf="button">
        <button (click)="removeHandler()" [disabled]="submittingRemove || !canRemove" class="btn btn-secondary btn-lg"
          type="button">
          {{removeButton}}
          <span class="spinner-border" *ngIf="submittingRemove"></span>
        </button>
      </div>

      <div class="col-12 col-md-3 mb-3">
        <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg"
          type="button">
          {{submitButton}}
          <span class="spinner-border" *ngIf="submitting"></span>
        </button>
      </div>
    </div>
  </fieldset>
</form>

<ng-template #loadingTemplate>
  <div class="col card full-loading">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

